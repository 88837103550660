import './App.css';
import GlobalStyle from './Global/GlobalStyle';
import { RoutesApp } from './RoutesApp';

function App() {
  return (
    <div className="App">
      <GlobalStyle />

      <RoutesApp />
      <header className="">
      </header>
    </div>
  );
}
export default App;
