import { Dialog } from 'primereact/dialog';
import { useContext, useState } from 'react';
import FikContext from '../../Contexts/FikSiteContext';
import './style.css';
import { TiAttachment } from "react-icons/ti";
import he from 'he';

export const ModalTrabalheNaFik = () => {
    const { visibleModalTrabalheNaFik, setVisibleModalTrabalheNaFik } = useContext(FikContext);
    const [formData, setFormData] = useState({
        nome: '',
        email: '',
        celular: '',
        linkedin: '',
        curriculo: null,
    });
    const [filledInputs, setFilledInputs] = useState({
        nome: false,
        email: false,
        celular: false,
        linkedin: false,
        curriculo: false,
    });

    const fechaELimpaModal = () => {
        setVisibleModalTrabalheNaFik(false);
        setFormData({
            nome: '',
            email: '',
            celular: '',
            linkedin: '',
            curriculo: null,
        });
        setFilledInputs({
            nome: false,
            email: false,
            celular: false,
            linkedin: false,
            curriculo: false,
        });
    };

    const handleChange = (e) => {
        const { id, value, type, files } = e.target;
        let newValue = type === 'file' ? files[0] : value;
        newValue = type === 'tel' ? value.replace(/\D/gi, '') : value;

        setFormData((prevFormData) => ({
            ...prevFormData,
            [id]: newValue,
        }));

        setFilledInputs((prev) => ({
            ...prev,
            [id]: type === 'file' ? !!newValue : !!value,
        }));
    };
    const isFormValid = () => {
        return (
            filledInputs.nome &&
            filledInputs.email &&
            filledInputs.celular &&
            filledInputs.curriculo
        );
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const htmlContent = `
        <h1>Novo Formulario Trabalhe na Fik</h1>
        <p><strong>Nome:</strong> ${he.encode(formData.nome)}</p>
        <p><strong>E-mail:</strong> ${he.encode(formData.email)}</p>
        <p><strong>Celular:</strong> ${formData.celular}</p>
        <p><strong>LinkedIn:</strong> ${he.encode(formData.linkedin)}</p>
    `;

        const formDataToSend = new FormData();
        formDataToSend.append('email', formData.email);
        formDataToSend.append('subject', 'Candidatura para Trabalhar na Fik');
        formDataToSend.append('html', htmlContent);
        if (formData.curriculo) {
            formDataToSend.append('files', formData.curriculo);
        }

        try {
            const response = await fetch('https://sendmail.fikdigital.com.br/', {
                method: 'POST',
                body: formDataToSend,
            });

            if (!response.ok) {

                throw new Error('Erro na requisição');
            }
            const resultado = await response.text();
            console.log('Resposta do servidor:', resultado);
            fechaELimpaModal();
        } catch (error) {
            console.log('Erro:', error);
        }
    };


    return (
        <Dialog
            visible={visibleModalTrabalheNaFik}
            style={{ width: '80vw', zIndex: '8000' }}
            onHide={() => setVisibleModalTrabalheNaFik(false)}
            role="dialog"
            aria-labelledby="dialog-title"
        >
            <div className='containerModal'>
                <div className='containerTituloEImagem'>
                    <h3 id="dialog-title" className='tituloEsquerdo'>FAÇA PARTE <br />
                        DO NOSSO <span>TIME </span>!
                    </h3>
                </div>
                <div className='containerDireito'>
                    <div className='containerbtnFechar'>
                        <button
                            className='btnFechar'
                            onClick={fechaELimpaModal}
                            aria-label="Fechar"
                        >
                            X
                        </button>
                    </div>
                    <div className='containerAltura'>
                        <h3 className='tituloDireito'>
                            SE VOCÊ SE IDENTIFICA COM <span className='estilizarPalavraDados'>A NOSSA CULTURA</span>, CADASTRE SEU CURRÍCULO E  <span className='estilizarPalavraDados'>VAMOS JUNTOS!</span>
                        </h3>

                        <form onSubmit={handleSubmit}>
                            <div className='containerLabelItemSozinho'>
                                <label htmlFor="nome">Seu nome completo</label>
                                <input className={filledInputs.nome ? 'border-filled' : ''} id="nome" placeholder='Seu nome completo' required value={formData.nome} onChange={handleChange} />
                            </div>

                            <div className='containerLabelItemSozinho'>
                                <label htmlFor="email">E-mail</label>
                                <input className={filledInputs.email ? 'border-filled' : ''} id="email" placeholder='Seu melhor email' type="email" required value={formData.email} onChange={handleChange} />
                            </div>

                            <div className='containerDoisElementos'>
                                <div className='containerLabel'>
                                    <label htmlFor="celular">Celular com DDD</label>
                                    <input id="celular"
                                        type='tel'
                                        className={filledInputs.celular ? 'border-filled' : ''}
                                        placeholder='(00) 00000-0000'
                                        required
                                        maxLength={11}
                                        value={formData.celular}
                                        onChange={handleChange}
                                        pattern='[0-9]{11}'
                                    />
                                </div>

                                <div className='containerLabel'>
                                    <label htmlFor="linkedin">Link para o seu linkedin</label>
                                    <input className={filledInputs.linkedin ? 'border-filled' : ''} id="linkedin" placeholder='linkedin.com.br/in/seu-perfil' value={formData.linkedin} onChange={handleChange} />
                                </div>
                            </div>

                            <div className='containerLabelCurriculo'>
                                <label htmlFor="curriculo" className="custom-file-upload">
                                    <TiAttachment /> {formData?.curriculo ? formData?.curriculo.name : 'ANEXAR CURRÍCULO'}
                                </label>
                                <input className='inputModalTrabalheConosco'
                                    type="file"
                                    id="curriculo"
                                    accept=".pdf"
                                    style={{ display: "none" }}
                                    required
                                    onChange={handleChange}
                                />
                            </div>

                            <button className='btnEnviar' disabled={!isFormValid()}>ENVIAR</button>
                        </form>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};
