import faixa from "../../Assets/FikAutomation/ListagemFikautomation.png"
import { FaixaStyle } from "./style"
import icon1 from "../../Assets/FikAutomation/IconesAutomation-01.svg"
import icon2 from "../../Assets/FikAutomation/IconesAutomation-02.svg"
import icon3 from "../../Assets/FikAutomation/IconesAutomation-03.svg"
import icon4 from "../../Assets/FikAutomation/IconesAutomation-04.svg"
import icon5 from "../../Assets/FikAutomation/IconesAutomation-05.svg"
import icon6 from "../../Assets/FikAutomation/IconesAutomation-06.svg"
import icon7 from "../../Assets/FikAutomation/IconesAutomation-07.svg"
import icon8 from "../../Assets/FikAutomation/IconesAutomation-08.svg"


export const Faixas = () => {

    const fikAutomationIcons = [
        { id: 1, icon: icon1, description: "DASHBOARD DE MONITORAMENTO" },
        { id: 2, icon: icon2, description: "CRIAÇÃO E VERSIONAMENTO DE PROCESSOS" },
        { id: 3, icon: icon3, description: "GESTÃO DE ASSETS" },
        { id: 4, icon: icon4, description: "CRIAÇÃO DE FILAS DE EXECUÇÕES" },
        { id: 5, icon: icon5, description: "INICIAÇÃO REMOTA DE PROCESSOS" },
        { id: 6, icon: icon6, description: "SUPORTE A MÚLTIPLAS LINGUAGENS DE PROGRAMAÇÃO" },
        { id: 7, icon: icon7, description: "MONITORAMENTO DE RECURSOS" },
        { id: 8, icon: icon8, description: "AGENDAMENTO DE PROCESSOS" }
    ]

    return (
        <>
            {fikAutomationIcons.map(item => (

                <FaixaStyle>
                    <div className="faixa-card" key={item.id}>
                        <div>
                            <div>
                                <img className="icon" src={item.icon} />
                            </div>
                            <div>
                                <p className="details">{item.description}</p>
                            </div>
                        </div>
                        <img className="imgFaixa" src={faixa} alt={item.description} />
                    </div>
                </FaixaStyle>
            ))
            }
        </>
    )
}