import { HeaderComponentRetorno } from "./style";
import logo from '../../Assets/LogoFik/logo.png';
import iconInstagram from '../../Assets/Icons/instagramIcon.svg';
import iconLinkedin from '../../Assets/Icons/linkedinIcon.svg';
import { useContext } from "react";
import FikContext from "../../Contexts/FikSiteContext";

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const HeaderComponent = ({ styleData }) => {
    const { handleClick } = useContext(FikContext);
    const location = useLocation();

    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.substring(1)); // remove o '#'
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);

    return (
        <HeaderComponentRetorno>
            <div className="containerHeader">
                <a href="/">
                    <img src={logo} alt="Logo da Empresa" />
                </a>
                <nav style={styleData}>
                    <a className="opcoesNavegacao btn" href="/#nossas-solucoes">SOLUÇÕES</a>
                    <a className="opcoesNavegacao btn" href="/quemSomos">SOBRE NÓS</a>
                    <a className="opcoesNavegacao btn" href="/QuemSomos/#Eventos">EVENTOS</a>
                    <div>
                        <button onClick={handleClick}>CONTATAR A FIK</button>
                    </div>
                </nav>
            </div>
        </HeaderComponentRetorno>
    );
};

