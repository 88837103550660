import { Dialog } from 'primereact/dialog';
import { useContext, useState } from 'react';
import FikContext from '../../Contexts/FikSiteContext';
import he from 'he'; // Importando a biblioteca he
import './style.css';

export const ModalLeads = () => {
    const { visible, setVisible } = useContext(FikContext);
    const [formData, setFormData] = useState({
        nome: '',
        empresa: '',
        segmento: '',
        cargo: '',
        email: '',
        telefone: '',
        mensagem: '',
        aceite: false,
    });
    const [filledInputs, setFilledInputs] = useState({
        nome: false,
        empresa: false,
        segmento: false,
        cargo: false,
        email: false,
        telefone: false,
        mensagem: false,
        aceite: false,
    });

    const fechaELimpaModal = () => {
        setVisible(false);
        setFormData({
            nome: '',
            empresa: '',
            segmento: '',
            cargo: '',
            email: '',
            telefone: '',
            mensagem: '',
            aceite: false,
        });
        setFilledInputs({
            nome: false,
            empresa: false,
            segmento: false,
            cargo: false,
            email: false,
            telefone: false,
            mensagem: false,
            aceite: false,
        });
    };

    const handleChange = (e) => {
        const { id, value, type, checked } = e.target;
        const newValue = type === 'checkbox' ? checked : value;

        if (id === 'telefone') {
            const filteredValue = value.replace(/[^0-9]/g, '');
            setFormData({ ...formData, [id]: filteredValue });
            setFilledInputs((prev) => ({
                ...prev,
                [id]: filteredValue.length >= 9 && filteredValue.length <= 15,
            }));
        } else {
            setFormData({ ...formData, [id]: newValue });
            setFilledInputs((prev) => ({
                ...prev,
                [id]: newValue ? true : false,
            }));
        }
    };

    const isFormValid = () => {
        return (
            filledInputs.nome &&
            filledInputs.empresa &&
            filledInputs.segmento &&
            filledInputs.cargo &&
            filledInputs.email &&
            filledInputs.telefone &&
            filledInputs.mensagem &&
            formData.aceite
        );
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validação do telefone antes do envio
        if (formData.telefone.length < 9 || formData.telefone.length > 15) {
            alert('O telefone deve ter entre 9 e 15 dígitos.');
            return;
        }

        const htmlContent = `
            <h1>Informacoes do Formulario modal BPO TECH</h1>
            <p><strong>Nome:</strong> ${he.encode(formData.nome)}</p>
            <p><strong>Empresa:</strong> ${he.encode(formData.empresa)}</p>
            <p><strong>Segmento:</strong> ${he.encode(formData.segmento)}</p>
            <p><strong>Cargo:</strong> ${he.encode(formData.cargo)}</p>
            <p><strong>E-mail:</strong> ${he.encode(formData.email)}</p>
            <p><strong>Telefone:</strong> ${he.encode(formData.telefone)}</p>
            <p><strong>Mensagem:</strong> ${he.encode(formData.mensagem)}</p>
        `;

        const payload = {
            email: formData.email,
            subject: `Contato capturado pelo formulario BPO TECH`,
            html: htmlContent
        };

        try {
            const response = await fetch('https://sendmail.fikdigital.com.br/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                },
                body: JSON.stringify(payload),
            });

            if (!response.ok) {
                throw new Error('Erro na requisição');
            } else {
                fechaELimpaModal();
            }

            const resultado = await response.text();
            console.log('Resposta do servidor:', resultado);
        } catch (error) {
            console.error('Erro:', error);
        }
    };

    return (
        <Dialog
            visible={visible}
            style={{ width: '80vw', zIndex: '8000' }}
            onHide={fechaELimpaModal}
        >
            <div className='containerModal'>
                <div className='containerTituloEImagemBPO'>
                    <h3 className='tituloEsquerdo'>VAMOS JUNTOS <br />
                        <span>AUTOMATIZAR </span> OS <br />
                        RESULTADOS <br />
                        DA SUA EMPRESA!
                    </h3>
                </div>
                <div className='containerDireito'>
                    <div className='containerbtnFechar'>
                        <button className='btnFechar' onClick={fechaELimpaModal}>X</button>
                    </div>
                    <div>
                        <h3 className='tituloDireito'>PREENCHA OS <span className='estilizarPalavraDados'>DADOS</span> PARA MELHOR <br />
                            ENTENDIMENTO DO SEU NEGÓCIO.
                        </h3>

                        <form onSubmit={handleSubmit}>
                            <div className='containerLabelItemSozinho'>
                                <label htmlFor="nome">Nome</label>
                                <input className={filledInputs.nome ? 'border-filled' : ''} id="nome" placeholder='Seu nome completo' value={formData.nome} onChange={handleChange} />
                            </div>
                            <div className='containerDoisElementos'>
                                <div className='containerLabel'>
                                    <label htmlFor="empresa">Empresa</label>
                                    <input className={filledInputs.empresa ? 'border-filled' : ''} id="empresa" placeholder='Empresa' value={formData.empresa} onChange={handleChange} />
                                </div>
                                <div className='containerLabel'>
                                    <label htmlFor="segmento">Segmento</label>
                                    <select className={filledInputs.segmento ? 'border-filled' : ''} id="segmento" value={formData.segmento} onChange={handleChange}>
                                        <option value="">Selecione</option>
                                        <option value="RH">RH</option>
                                        <option value="Suprimentos">Suprimentos</option>
                                        <option value="Financeiro">Financeiro</option>
                                        <option value="Logística">Logística</option>
                                        <option value="Planejamento">Planejamento</option>
                                        <option value="Operacional">Operacional</option>
                                        <option value="Tributária">Tributária</option>
                                    </select>
                                </div>
                            </div>

                            <div className='containerLabelCargo'>
                                <label htmlFor="cargo">Cargo</label>
                                <select className={filledInputs.cargo ? 'border-filled' : ''} id="cargo" value={formData.cargo} onChange={handleChange}>
                                    <option value="">Selecione</option>
                                    <option value="Dono/CEO/Presidente">Dono/CEO/Presidente</option>
                                    <option value="Vice-presidente">Vice-presidente</option>
                                    <option value="Diretor/Gestor Executivo">Diretor/Gestor Executivo</option>
                                    <option value="Gerente">Gerente</option>
                                    <option value="Coordenador/Supervisor">Coordenador/Supervisor</option>
                                    <option value="Analista/Assistente">Analista/Assistente</option>
                                    <option value="Estagiário/Estudante">Estagiário/Estudante</option>
                                    <option value="Outros">Outros</option>
                                </select>
                            </div>

                            <div className='containerDoisElementos'>
                                <div className='containerLabel'>
                                    <label htmlFor="email">E-mail corporativo</label>
                                    <input type='email' className={filledInputs.email ? 'border-filled' : ''} id="email" placeholder='exemplo@empresa.com.br' value={formData.email} onChange={handleChange} />
                                </div>

                                <div className='containerLabel'>
                                    <label htmlFor="telefone">Telefone Corporativo</label>
                                    <input type='tel' className={filledInputs.telefone ? 'border-filled' : ''} id="telefone" placeholder='(00) 00000-0000' value={formData.telefone} required maxLength={15} onChange={handleChange} />
                                </div>
                            </div>

                            <div className='containerLabelItemSozinho'>
                                <label htmlFor="mensagem">Mensagem</label>
                                <textarea className={filledInputs.mensagem ? 'border-filled' : ''} id="mensagem" placeholder='Olá, boa tarde!' value={formData.mensagem} onChange={handleChange} />
                            </div>

                            <span className='containerCheckbox'>
                                <input className={filledInputs.aceite ? 'border-filled inputCheckbox' : 'inputCheckbox'} type='checkbox' id="aceite" checked={formData.aceite} onChange={handleChange} />
                                <label htmlFor="aceite">Aceito a utilização dos dados cedidos nesse formulário para a realização de um contato comercial.</label>
                            </span>

                            <button className='btnEnviar' disabled={!isFormValid()}>ENVIAR</button>
                        </form>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};
