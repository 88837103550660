import { StyledButton } from "./style.js";

export const BtnFaleComNossoTime = ({ onClick, children, bgColor, ariaLabel }) => {
    return (
        <StyledButton
            textColor="white"
            onClick={onClick}
            bgColor={bgColor}
            aria-label={ariaLabel}
        >
            {children}
        </StyledButton>
    );
};

