import { useContext } from "react";
import { MenuDeAcessibilidadeRetorno } from "./style";
import { ImBrightnessContrast } from "react-icons/im";
import iconContrast from '../../Assets/Icons/contrastIcon.svg';  // Caminho relativo para a imagem

import FikContext from "../../Contexts/FikSiteContext";

export const MenuDeAcessibilidadeComponente = () => {
    const { fontSize, setFontSize, constraste, setContraste, alterarFonteSize, alterarContraste, abrePagina } = useContext(FikContext);

    return (
        <MenuDeAcessibilidadeRetorno>
            <div className="containerControleLetras containerControleLetrasCont">
                <div className="containerControleLetrasbtns">
                    <button className="buttonIconsBarraAcessibilidade" onClick={() => alterarFonteSize(1)}><figure className="btnsAcessibilidade">A+</figure></button>
                    <button className="buttonIconsBarraAcessibilidade" onClick={() => alterarFonteSize(-1)}><figure className="btnsAcessibilidade">A-</figure></button>
                </div>
            </div>
            <div className="containerControleLetras">
                <div>
                    <button className="buttonIconsBarraAcessibilidade" onClick={() => alterarContraste(!constraste)}>
                        <img className="btnsAcessibilidade" src={iconContrast} alt="Ícone com o logo do Linkedin, que redireciona para nossa página na rede social." />
                    </button>
                </div>
            </div>
        </MenuDeAcessibilidadeRetorno>
    )
};
