import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import { PaginaInicial } from "../Pages/PaginaInicial";
import { PageFikSmart } from "../Pages/FikSmart";
import { PageQuemSomos } from "../Pages/QuemSomos";
import { useContext } from "react";
import { FikContext } from "../Contexts/FikSiteContext";
import { MenuDeAcessibilidadeComponente } from "../Components/BarraAcessibilidadeComponent";
import { HeaderComponent } from "../Components/HeaderComponent";
import { FooterComponent } from "../Components/FooterComponent";
import { FikAnalytics } from "../Pages/FikAnalytics";
import { FikAutomation } from "../Pages/FikAutomation";
import { PageFikOn } from "../Pages/FikOn"
import { Consultoria } from "../Pages/consultoria";
import { PaginaJobs } from "../Pages/Jobs";


const MainFrame = () => {

    const { fontSize, setFontSize, constraste, setContraste, alterarFonteSize, alterarContraste, abrePagina } = useContext(FikContext);

    return <div style={{ '--fontSize': fontSize }} className={`main-frame`}>
        <div>
            <MenuDeAcessibilidadeComponente />
            <HeaderComponent styleData={{ 'filter': constraste ? 'brightness(100%) contrast(200%)' : 'unset' }} />
        </div>
        <div style={{ 'filter': constraste ? 'brightness(100%) contrast(200%)' : 'unset' }}>
            <Outlet />

        </div>
        <div style={{ 'filter': constraste ? 'brightness(100%) contrast(200%)' : 'unset' }}>
            <FooterComponent />

        </div>

    </div >
}

export const RoutesApp = ({
}) => {
    return (
        <Routes>
            <Route element={<MainFrame />}>
                <Route path='*' element={<Navigate to="/" />} />
                <Route path="/" element={<PaginaInicial />} />
                <Route path="/FikOn" element={<PageFikOn />} />
                <Route path="/FikAnalytics" element={<FikAnalytics />} />
                <Route path="/fikAutomation" element={<FikAutomation />} />
                <Route path="/FikSmart" element={<PageFikSmart />} />
                <Route path="/Consultoria" element={<Consultoria />} />
                <Route path="/Jobs" element={<PaginaJobs />} />
                <Route path="/QuemSomos" element={<PageQuemSomos />} />
            </Route >
        </Routes >
    );
};
