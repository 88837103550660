import styled from "styled-components";

export const StyledButton = styled.button`
  background-color: ${({ bgColor }) => bgColor || 'var(--menu-blue-color)'};
  color: ${({ textColor }) => textColor || 'white'};
  border-radius: 15px;
  font-weight: bold;
  line-height: 1em;
  width: 100%;
  padding: 1em 1.5em;
  font-size: 1.0em;
z-index: 1;
font-family:Arial;


  @media (min-width: 768px) {
    font-size: 0.8em;
    max-width: 400px;
  }

  @media (min-width: 1024px) {
    font-size: 0.8em;
    max-width: 400px;
  }

  @media (min-width: 1044px) {
    font-size: 1.3em;
    max-width:  fit-content;
  }
`;