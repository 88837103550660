import styled from "styled-components";
import segundaImagem from "../../Assets/Fundos/Fundo.png";
import fundoBrancoFormatado from "../../Assets/Fundos/fundoBrancoFormatado.png"

export const ContainerPaginaInicial = styled.div`
font-size: var(--fontSize);
z-index: 2;
position: relative;

.containerUlSolucoes{
display:flex;
justify-content:center;
}
.parteUmPaginaInicial{
  width: 100vw;
  min-height: 100vh;
  background-image: url(${(props) => props.image});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% auto;
 background-color: #1b8fb7;
  font-family: Orbitron;
  color: white;
  align-items:center;
 display:flex;
 margin-top: 120px;
@media (min-width: 200px) and (max-width: 1024px) {
display: flex;
flex-direction: column;
gap: 50px;
margin-top: 220px;


}

}

  .containerPrimeiroParagrafo {
   display: flex;
   flex-direction: column;
   gap: 20px;
   padding: 0px 0px 0px 10%; 
   line-height:28px;
  width: 50%;

@media (max-width: 400px) {
width: 90%;
padding: 0px;

}

  }
.videoFIK{
margin-right: 10%;
width: 50%;

@media (min-width: 768px) and (max-width: 1024px) {
width: 96%;
padding: 0px  5px;
margin-right: 0%;
margin-bottom: 30px;
}

@media (max-width: 400px){
  width: 96%;
margin: 0px  5px;
   height: auto;
}
@media (min-width:1200px ){
margin-right: 0%;
        margin-right: 10%
}
}


@media (min-width: 768px) and (max-width: 1024px) {
.containerPrimeiroParagrafo{
margin-top: 30px;
}
}
  .descricao {
    width: 100%;
    font-weight: 900;
    line-height: 1.8rem;
    font-size: 1.4em;
    padding: 0px 0px 0px 0px;
    line-height:35px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
@media (min-width: 1000px) {
    font-size: 2em;
}
@media (max-width: 400px) {

margin-top: 20px;
}
  }

  .descricao2 {
font-family:Arial;
    max-width: 50%;
    margin: 10px 0px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    font-size: 1.2em;
@media (min-width: 1000px) {
    font-size: 1.5em;
}
@media (max-width: 425px) {
    max-width: 90%;
 font-size: 1.4em;
}

  }

  .falarComTime {
     margin: 10px 0px;
    width: 21rem;
    color: white;
    background-color: var(--menu-blue-color);
    padding: 20px 15px;
    border-radius: 15px;
    font-weight: 500;
    font-size: 20px;
  }

.parteDoisPaginaInicial{
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.testeClass {
background-repeat: no-repeat;
margin-top:-175px;
padding-top: 175px;
background-origin:center center;
position: relative;
overflow: hidden;
padding-bottom: 100px; 
z-index: -1;
@media (max-width: 550px) {
margin-top:-300px;

}

}

.testeClass > * {
  position: relative;
z-index: 2;
}

.testeClass::before{
  content:'';
  display: block;
  position: absolute;
  top: 10%;
//
  left: -22%;
  transform: rotate(-45deg);
  width: 100%;
  height: 100%;
  z-index:1;
  background-image: url(${segundaImagem});
  background-position: 0 0; 
  background-size: 3077px   1945px; 
}


.darkText {
  color: var(--menu-blue-color);

}
.descricaoSegundoTitulo {
    color: var( --primary-color);
    margin: 0px 0px;
    font-size: 2em;
}

.containerSegundoParagrafo{
   display: flex;
   flex-direction: column;
   gap: 8px;
   padding: 0px 10% 0px 10%;
   font-weight: 1000;
   //margin-top:1.5rem;
}

.containerCardAutomacaoRobotica{

width: 50%;
display:flex;
align-items:center;
}

.containerVideo{
    display:flex;
display:flex;
flex-direction:center;
justify-content:center;
box-sizing: border-box;
padding: 40px 20px;
background-color: #fff;
}
.containerVideo video{
max-width:100vw;

@media (min-width: 768px) and (max-width: 1024px) {
width: 96%;
}
@media (max-width: 400px){
  width: 96%;
}
}
.balaoDiv {
  position: relative; 
  top: 0;         
  left: 0;            

  width: 96%;
max-width: 1200px;        
  height: auto;      
  background-color: transparent; 
  z-index: 2;
margin: 0 auto;
  transform: translateY(-50%);

@media (max-width: 600px) {
    margin-top: 200px;

}
@media (max-width: 400px) {
margin-top: 320px;


}

}

.containerParceriasQueTransformam{
display:flex;
flex-direction: column;
align-items: center;
justify-content: center;
h4{
text-align: center;
}
}
.conteudoBalaoEntreDivs{
background-color:white;
width: 100%;
min-height:122px;
border-radius: 15px;
align-items:center;
justify-content:center;
box-shadow: 
    inset 0 0 5px rgba(0, 0, 0, 0.3), 
    0 10px 20px rgba(0, 0, 0, 0.6); 
     
  display:grid;
grid-template-columns: 30% 70%;
gap: 10px 20px;

  padding: 20px 40px;
box-sizing: border-box;
@media screen and (max-width: 1000px){
grid-template-columns: 100%;
}

}

.textoColoridoBalao{
color:var(--primary-color);
font-size: var(--fontSize);

}
.ulBalao{
display:grid;
grid-template-columns: repeat(auto-fill, minmax(20%, auto));
gap: 10px;
justify-items:center;
flex-direction:row;
align-items:center;
font-size: var(--fontSize);

@media screen and (max-width: 767px){
grid-template-columns: 100%;
}

}
li{
display:flex;
flex-direction:column;
align-items:center;
justify-content:center;
gap:10px;
span{
font-size: 12px;
font-weight: 500;
}
}

.tituloBalao{
font-size: 18px;
font-weight: 500;
display:flex;
flex-direction:column;
}
.tituloBalao strong {
font-weight: 900;   
}
.resultadosCardBalao{
color:var(--primary-color);
font-size: 25px;
font-weight: 900;
display:flex;
align-items:center;
justify-content:center;
}

.tagTextoLiBalao {
    display: flex;
    flex-direction: column; 
    align-items: center;    
    text-align: center;     
    font-size:14px;
font-family: arial;


}

.containerTerceiroParagrafo{
   padding: 0px 0% 0px 0%;
   display:flex;
  flex-direction:row-reverse;
  width: 50%;
  margin: 0px auto;

justify-content:end;
}
.containerInformacoesTerceiroParagrafo{
display:flex;
padding: 0px 10% 30px 30px;
background-color:white;
flex-direction: column;
gap:20px;
width:50%;  
font-size: 1.2em;
p{
font-family: arial;
}
@media (min-width: 1100px) {
width:65%
}

}

.somosTecnologiaTitle{
color: var( --primary-color);
font-size: 2em;
font-weight: 900;
padding-top: 30px;;
}

.container {
    display: flex;
    align-items: center;
    width: 100%; 
    position: relative;
    padding: 25px 0% 25px 10%;
@media (max-width: 400px) {
    padding: 25px 0% 25px 0%;

}
}



.nossasSolucoesTitle {
   margin: 0;
   font-weight: 900;
   color: var( --primary-color);
   font-size:2em;
   align-items:center;
   padding: 70px 20px; 
}

.line {
    flex: 1; 
    height: 5px; 
    background-color:var(--primary-color); 
   color: var(--primary-color);
}
.containerProdutosFik {
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(30%,1fr));
    flex-wrap: wrap;
    background-color: white;
    justify-content: space-between;
    padding: 40px 0px 50px;
    box-sizing: border-box;  
    align-items: stretch;
    gap: 50px; 
    width:80%;
    font-size: var(--fontSize);
@media (min-width: 1000px) {
  padding: 40px 40px 50px;
  width:80%;
}
}

.containerProdutosFik li {

    font-size: 1em;
    background-color: white;
    width: 100%; 
    height: 100%;
    box-sizing: border-box;
    padding: 40px 15px; 
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 100px auto 50px;
    align-items: start;
    border-radius: 25px;
    overflow:hidden;
    gap: 10px 20px;
font-family: arial;
    box-shadow: 
        inset 0 0 5px rgba(0, 0, 0, 0.3), 
        0 10px 15px rgba(0, 0, 0, 0.4); 
@media (min-width: 1000px) {
   box-shadow: 
        inset 0 0 5px rgba(0, 0, 0, 0.3), 
        0 10px 15px rgba(0, 0, 0, 0.6); 
}
}

.containerProdutosFik li .containerSaibaMaisLi{
    align-items: end;
}

.containerProdutosFik li figure{
  min-height: 100px;
}

.logoSVG {
    width: 100%;
    max-width: 150px; 
    height: auto; 
}

.containerSaibaMaisLi{
    display: flex;
    align-items: center;
    width: 100%; 
    position: relative; 
    height:50px;
    padding: 0px 0% 0px 10px;
}

.lineSaberMais{
    flex: 1; 
    height: 1px; 
    background-color:var(--primary-color); 
   color: var(--primary-color);
}

.ancoraSaberMais{
  margin: 0;
  font-size: 0px;
  font-weight: 500;
  color: var( --primary-color);
  font-size:18px;
  padding: 0px 20px;
  border: 0;
}

.tituloLogoJOBSeConsultoria{
  font-size:4em;
  font-weight: 900;
text-transform:uppercase;
 color: #2b9bc6;
width: 100%;
overflow:hidden;
text-overflow: ellipsen;
}
.containerBPONuvem{
padding:50px 0px;
}
.containerBPO {
    background-color: white;
    padding: 0px 10% 0px 10%;
    background-image: url(${fundoBrancoFormatado});
    background-repeat: no-repeat;
    background-position: center;
    background-size: covert;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-around;
.containerBPOInfos{
 width:80%;
 display:flex;
 flex-direction:column;
 gap:50px;
p{
font-family: arial;
}

@media (min-width: 1000px) {
 width:50%;
}
p{
font-size:1.2em;
}
}
}

.roboPaginaInicialImagem{
height:100%;
width: 100%; 
}

.BPOTechTitle{
color: var( --primary-color);
font-size: 65px;
font-weight: 900;
}
.containerPrincipaisMercados{
display:flex;
flex-direction: column;
padding: 0px 10% 0px 10%;
background-color:  #2b9bc6;;
height:320px;
width: 100vw;
justify-content:center;
gap:50px;
color:white;
h3{
font-size:20px;
max-width: 870px;
margin:0 auto;
width: 100%;
}
li{
span{
font-size:10px;

@media (min-width: 800px) {
font-size:12px;
}
}
}

}
.divAdornoAzul {
    display: flex;
    margin: 0; 
    padding: 0; 
}

.adornoAzul {
    transform: scaleY(-1);
    display: flex;
    width: 100%;
    line-height: 0; 
    vertical-align: top; 
    object-fit: cover; 
    position:relative;
    top: 120px;
}

.containerListaEAdornoAzul{
    position:relative;
    bottom:100px;
    display:flex;
    flex-direction:column;
    /* pointer-events: none; */

}

.figurePrincipaisMercadosAtuacao{
    height:80px;
    width: 80px;
    background-color:white;
    border-radius:10px;
    display:flex;
    align-items:center;
    justify-content:center;
img{
    height:60px;
    max-width:100%;
}
}

.containerCasesDeSucesso{
    width: 100vw;
    min-height: 600px;
    background-image: url(${segundaImagem});
    background-repeat: no-repeat;
    background-position: center;
    display:flex;
    justify-content:center;
    align-items:center;
}

.cardCasesDeSucesso{
    background-color:white;
    width: 80%;
    min-height:400px;
    border-radius:40px;
    display:flex;
    flex-direction:column;
    justify-content:space-around;
    gap:20px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3), 0 10px 20px rgba(0, 0, 0, 0.9);
   padding: 25px 0px;
 @media (min-width: 1000px) {
    width: 80%;
}
h3{
    display:flex;
    justify-content:center;
    color: var( --primary-color);
    font-size: 1.5rem;
    font-weight: 900;
}

h4{
    display:flex;
    justify-content:center;
    color: var( --primary-color);
    font-size: 1.0rem;
    font-weight: 900;
}
}

.containerImagensLogoClientes{
    display:flex;
    justify-content:space-around;
    display:flex;
    align-items:center;
.imagensLogoClientes{
    min-height:80px;
    max-height:80px;
}
}
.imagensLogoClientesVerdemar{
    height:80px;
}

@media (max-width: 1000px) {

.containerTerceiroParagrafo{
    display:flex;
    justify-content:start;
    width: 100vw
}

.tagTextoLiBalao{
font-size:1px;


}
}

@media (max-width: 767px){
.BPOTechTitle{
font-size:50px;
}
.tagTextoLiBalao{
font-size:12px;
}

 .imagensLogoClientes {
    min-height: 80px;
    max-height: 80px;}


.cardCasesDeSucesso h3 {
    font-size: 1.1rem;
    font-weight: 900;
    text-align: center;
}

.tagTextoLiBalao{
font-size:10px;
}

.ulBalao{

flex-direction:column;
gap:4px;
}


.containerProdutosFik {
    grid-template-columns: 100%;
    gap: 30px 10px;
}

.conteudoBalaoEntreDivs{
height:500px;
}
.containerVideo{
  padding:80px 0px 0px 0px;
  display:flex;
}
.tituloBalao{
padding:5px;
}

.containerImagensLogoClientes{
display:flex;
align-items:center;
flex-direction:column;
gap:20px
}

.parteDoisPaginaInicial{
  width: 100vw;
  background-image: url(${segundaImagem});
  background-repeat: repeat;
  background-position: center center;
  background-size: cover;
}

.cardCasesDeSucesso{
    height: 70%;
}

.containerPrincipaisMercados{
  min-height:300px;
  width: 100vw; 

}
.descricaoSegundoTitulo{
font-size: 1.7rem;
}
.somosTecnologiaTitle{
font-size: 2em;
}

.ulCardPrincipaisMercados li{
display:flex;
align-items:center;
justify-content:center;
}

.parteDoisPaginaInicial{
  width: 100vw;
}

.containerBPO p{
font-size:1.3rem;
}

.adornoAzul{
display:none;
}

.roboPaginaInicialImagem{
display:none;
}
.nossasSolucoesTitle{
  width: 100vw;

}
.container{
  width: 100vw;
}

.containerProdutosFik li { 
    min-height: 400px;
    }

.containerBPOInfos{
width:100%;
}
}

@media (max-width: 850px){
.containerBPO{
margin-bottom: 60px;
}
.cardCasesDeSucesso{
    height: 90%;
}
.imagensLogoClientes {
    min-height: 50px;
    max-height: 50px;
}
.containerInformacoesTerceiroParagrafo{
display:flex;
padding: 0px 10% 30px 3%;
background-color:white;
flex-direction: column;
gap:20px;
width:100%;
font-size: 1.2rem;


}

.container {
    position: relative;
    padding: 0px 0% 0x 0%;
}

.containerBPOInfos{
width:100%;
}
}
.consultLogo{
max-width: 170px;
}

  `;

