import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`


*{
    margin: 0;
    padding: 0;
    box-sizing: border-box; 
}

img{
      /* max-width: 100%;
      max-height: 100%; */
    }

  html, body {
   width: 100vw;
   height: auto;
   display:block;
   flex-direction:row;
overflow-x: hidden;
  }

  #root{
    width: 100%;
    --primary-color: #2B9BC7;
}
  ul{list-style: none;}

`;

export default GlobalStyle;
