import { PrincipaisMercados} from "./style";

import iconeArea01 from "../../Assets/IconesAreas/IconesAreas01.svg";
import iconeArea02 from "../../Assets/IconesAreas/IconesAreas02.svg";
import iconeArea03 from "../../Assets/IconesAreas/IconesAreas03.svg";
import iconeArea04 from "../../Assets/IconesAreas/IconesAreas04.svg";
import iconeArea05 from "../../Assets/IconesAreas/IconesAreas05.svg";
import iconeArea06 from "../../Assets/IconesAreas/IconesAreas06.svg";
import iconeArea07 from "../../Assets/IconesAreas/IconesAreas07.svg";

export const BarraPrincipaisMercados = () => {
    
    return (
        <PrincipaisMercados>
          <div className="containerAzulPrincipaisMercados">
            <div className="top"> </div>
            <div className="conteudoPrincipaisMercados">
              <h3>NOSSOS PRINCIPAIS MERCADOS DE ATUAÇÃO</h3>
              <ul className="ulCardPrincipaisMercados">
                <li>
                  <figure className="figurePrincipaisMercados">
                    <img src={iconeArea01} alt="Ícone de cabeça com engrenagem." />
                  </figure>
                  <h4>RH</h4>
                </li>
                <li>
                  <figure className="figurePrincipaisMercados">
                    <img src={iconeArea02} alt="Ícone de engrenagem com uma seta ao redor, demonstrando um sistema." />
                  </figure>
                  <h4>SUPRIMENTOS</h4>
                </li>
                <li>
                  <figure className="figurePrincipaisMercados">
                    <img src={iconeArea03} alt="Ícone de uma calculadora" />
                  </figure>
                  <h4>FINANCEIRO</h4>
                </li>
                <li>
                  <figure className="figurePrincipaisMercados">
                    <img src={iconeArea04} alt="Ícone de três caixas. Uma em cima da outra." />
                  </figure>
                  <h4>LOGÍSTICA</h4>
                </li>
                <li>
                  <figure className="figurePrincipaisMercados">
                    <img src={iconeArea05} alt="Ícone de planejamento" />
                  </figure>
                  <h4>PLANEJAMENTO</h4>
                </li>
                <li>
                  <figure className="figurePrincipaisMercados">
                    <img src={iconeArea06} alt="Ícone de duas engrenagens." />
                  </figure>
                  <h4>OPERACIONAL</h4>
                </li>
                <li>
                  <figure className="figurePrincipaisMercados">
                    <img src={iconeArea07} alt="Ícone de um martelo batendo na mesa. Representando o setor tributário." />
                  </figure>
                  <h4>TRIBUTÁRIA</h4>
                </li>
              </ul>
            </div>
          </div>
        </PrincipaisMercados>
    );
};