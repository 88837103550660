import styled from "styled-components";

export const FooterRetorno = styled.footer`

.containerHeader{
font-size:10px;
width: 100vw;
min-height: 400px;
padding-left: 10%;
padding-right: 10%;
max-width: 100%;           
/* font-family: Orbitron; */
font-weight: 600; 
font-size: var(--fontSize); 
display: flex;
flex-direction: row;
gap:45px;
background-color: Whithe;
color: black;
   font-family:arial; 

}
.textDireitosReservadosMobile{
      display:none;
}
img{
   width: 120px;
   height: 70px;
   }
.logoContainer{
   padding:0px 0px 14px 0px;
}

.spanConfig{
   display: inline-flex;
   align-items: center;
   width: 100%;
   height: 100%;
}
.containerIconEinformacao{
   display: inline-flex;
   justify-content: flex-start;
   align-items: center;
   width: 100%;
   height: 100%;
}
.iconsFooter{
   display: block;
   width: 100%;
   max-width: 30px;
   height: auto;
}
.footer-container{
   display:flex;
   background-color: var(--bottom-primary-collor-white);
}
address{
   display:flex;
   flex-direction:column;
   gap:10px;
}
p{
   display:flex;
   gap:7px;
}

.mapaFik{
   max-width: 230px;
   max-height: 120px;
   border-radius:4px; 
}
.footer-containerInformacoesContato{
   height: 100%;
   display:flex;
   flex-direction: column;
   background-color: Whrite;;
   justify-content: space-between;
   width:25%;

}
.footer-containerInformacoesProdutosEConteudos{
   height: 100%;
   display:flex;
   flex-direction: column;
   width:20%;
   gap: 26px;
}

.footer-containerInformacoesForm{
   height: 100%;
   display:flex;
   flex-direction: column;
   justify-content: space-between;
   gap: 26px;
   width:25%;

}

.navProdutos{
   display:flex;
   flex-direction:column;
}

.tituloDeCardFooter{
   color:#2B9BC7;
   font-weight: 800; 
   padding:16px 0px 10px 0px;
font-family: Orbitron;
;
}

form{
   display:flex;
   gap:10px;
   flex-direction: column;
}

form button {
   background-color:var( --primary-color);
   border-radius: 5px ;
   color:var(--bottom-primary-collor-white);
   height: 30px;
   font-size: 15px;
font-family: Orbitron;


}

form input {
   background-color:#E6E6E6;
   border-radius: 7px ;
   height: 42px;
   border: 1px solid var( --primary-color);
   padding-left: 15px;

}
input::placeholder {
   font-family:Orbitron; 
   font-size: 10px;
}

.containerIcons{
   display: flex;
   gap:7px;
   margin-top:10px;

img{
   width: 14px;
   height: 14px;
}
.iconeRedeSocialFootter{
 font-size: var(--fontSize);
  width: 4em;
  height: 4em;
  max-width: 2em;
  max-height: 2em;
}
}
.navProdutos a{ 
margin: 0px 0px 10px 0px;
}
.iconeRedeSocialFootter{
 font-size: var(--fontSize);
  width: 2em;
  height: 2em;
  max-width: 4em;
  max-height: 4em;
}
@media (max-width: 1000px) {
 .containerHeader{flex-direction: column;}
.mapaFik{
   max-width: 330px;
   max-height: 220px;
   border-radius:4px; 
}
.footer-containerInformacoesProdutosEConteudos{
   width: 90%;

 }
 .footer-containerInformacoesContato{
 width: 90%;
 }
 .footer-containerInformacoesForm{
  width: 90%;
 }

.textDireitosReservados{
display:none;
}
.textDireitosReservadosMobile{
display:flex;
}
}


@media (max-width: 700px){

.pDesenvolvidoPor {
    white-space: nowrap; 
}

.pDesenvolvidoPor span {
    font-weight: 700; 
}

}


.containerTextosDireitosReservados{

width: 100vw;
padding-left: 10%;
padding-right: 10%;
max-width: 100%;    
display:flex;
justify-content: space-between;
font-size: 14px;
font-weight: 600;
margin-bottom: 20px;
gap:20px;
font-family: arial;
@media (max-width: 700px){

flex-direction: column;
}
}

`;
