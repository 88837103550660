import styled from 'styled-components';
import FundoCasesDeSucesso from "../../Assets/Fundos/Fundo.png";
import imagemAdorno from "../../Assets/Fundos/adornos-02.png";
import AdornoBranco from "../../Assets/Fundos/adorno-branco.png";
import AdornoBrancoTest from "../../Assets/Fundos/adorno-brancoTest.png";



export const ContainerQuemSomos = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
  font-size: var(--fontSize);
  margin-top: 120px;
@media (max-width: 768px) {
margin-top: 200px;
    }
  span {
    font-size: var(--fontSize);
  }

.footFaixaBranca { 
    width: calc(100% + 8px);
    height: 120px;
    background-image: url(${AdornoBranco});
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    transform: scaleX(-1);
    margin-top: -7vh;
    z-index: 2;

    @media (max-width: 488px) {
      margin-top: -2.9vh;
      height: 25px;
    }
}

.footPrincipaisMercados {
    width: calc(100% + 8px);
    height: 100px;
    background-image: url(${imagemAdorno});
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    transform: scaleX(-1);
    z-index: 1;
    margin-top: -1px;
    margin-bottom: -15vh;
    

    @media (max-width: 768px) {
      margin-bottom: -5vh;
      height: 25px;           
    }
}




.topoBrancoGaleria {
    width: calc(100% + 8px);
    height: 100px;
    background-image: url(${AdornoBrancoTest});
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    transform: scaleX(-1);
    z-index: 1;
    margin-top: -1px;
    margin-bottom: -8vh;
    
@media (max-width: 488px) {
      margin-bottom: -2vh;
      height: 25px;           
    }
 
}




//////////////////////////////////////////////// Faixa do Topo

.containerAzulFaixaDoTopo {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 0px auto 120px;
    width: 100vw;
    position: relative;
}

.conteudoFaixaDoTopo {
    position: relative;
    min-height: 120px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    background-color: var(--primary-color);
    color: #FFF;
    box-sizing: border-box;
    max-width: 100vw;

    h3{
      font-size:2rem;
      font-weight: bold;
      color: #ffffff;
      text-align: left;
      margin-left: 9vw;
      margin-right: 5vw;  
      margin-top: 10vh; 
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);

      @media (max-width: 768px) {
        margin-top: 7vh; 
        font-size: 1.2em;
      }
    }
}

.footFaixaDoTopo {
    width: calc(100% + 8px);
    height: 100px;
    background-image: url(${imagemAdorno});
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    transform: scaleX(-1);
    margin-top: -5px;

    @media (max-width: 768px) {
        height: 50px;
        margin-top: -1px;
    }
}

//////////////////////////////////////////////// Faixa Cases de Sucesso

.containerCasesDeSucesso{
  width: 100vw;
  min-height: 600px;
  /* background-image: url(${FundoCasesDeSucesso}); */
background-color: var(--primary-color);
  background-repeat: no-repeat;
  background-position: center;
  display:flex;
  justify-content:center;
  align-items:center;
margin-top: 5vh;
margin-bottom: -10vh;
  
}

.cardCasesDeSucesso{
  background-color:white;
  width: 80%;
  min-height:400px;
  border-radius:40px;
  display:flex;
  flex-direction:column;
  justify-content:center;
  gap:20px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3), 0 10px 20px rgba(0, 0, 0, 0.9);

  h3{
    display:flex;
    justify-content:center;
    color: var( --primary-color);
    font-size: 1.7em;
    font-weight: 900;

    @media (max-width: 768px) {
      text-align: center;
      margin-top: 5vh;
      font-size: 1.2em;
    }
  }

  h4{
    display:flex;
    justify-content:center;
    color: var( --primary-color);
    font-size: 1.5em;
    font-weight: 900;
    margin-top: 1vh;
    margin-bottom: 7vh;

    @media (max-width: 768px) {
      text-align: center;
      padding: 20px;
      margin-top: 0vh;
      margin-bottom: 3vh;
      font-size: 1.1em;
    }
  }
}

.containerImagensLogoClientes{
  display:flex;
  justify-content:space-around;
  display:flex;
  align-items:center;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    margin-bottom: 5vh;
  }
}

.imagensLogoClientes{
  min-height:80px;
  max-height:80px;

  @media (max-width: 768px) {
    flex-direction: column;    
    margin-bottom: 20px;
  }
}

//////////////////////////////////////////////// Faixa Eventos

.containerAzulEventos {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 80px auto 150px;
    width: 100vw;
    flex-direction: column;
    position: relative;
    margin-top: -11vh;
}
  
.conteudoEventos {
    position: relative;
    min-height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: var(--primary-color);
    color: #FFF;
    box-sizing: border-box;
    padding: 0px 20px;
    max-width: 100vw;
}
 
.footEventos {
    width: calc(100% + 8px);
    height: 100px;
    background-image: url(${imagemAdorno});
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: -5px;

    @media (max-width: 768px) {
        height: 50px;
    }
}

//////////////////////////////////////////////// Textos do Topo

.textosDoTopo {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  position: relative;
  overflow: hidden;

  h3{
    font-size: 1.5em;
    color: #000000;
    text-align: left;
    margin-top: 10vh;
    margin-left: 10vw;
    margin-right: 6vw;
font-family: arial;

    @media (max-width: 768px) {
      margin-top: 0vh;
      font-size: 1em;
    }
  }

  h4 {
  font-size: 2.4rem;
  font-weight: bold;
  color: var(--primary-color);
  text-align: left;
  margin-top: 10vh;
  margin-right: 20vw;

    @media (max-width: 768px) {
      font-size: 1.8em;
      margin-top: 40px;
      margin-left: 10vw;
    }
  }

  b {
  font-weight: bold;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
}


`;

export const TextsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  position: relative;
  overflow: hidden;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const MidSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  position: relative;
`;

export const MidTitleHistoria = styled.h1`
  font-size: 2rem;
  color: var(--primary-color);
  font-weight: bold;
  text-align: left;
  margin-left: 10vw;
  margin-bottom: 5vh;
  margin-top: 20vh;

  @media (max-width: 768px) {
    font-size: 1.8em;
    margin-bottom: 1vh
  }
`;

export const MidTitle = styled.h1`
  font-size: 2rem;
  color: var(--primary-color);
  font-weight: bold;
  text-align: left;
  margin-left: 10vw;
  margin-bottom: 5vh;
  margin-top: 1vh;

  @media (max-width: 768px) {
    font-size: 1.8em;
    margin-bottom: 1vh
  }
`;


export const OtherMidTitle = styled.h1`
  font-size: 2rem;
  color: var(--primary-color);
  font-weight: bold;
  text-align: left;
  margin-left: 10vw;

  @media (max-width: 768px) {
    font-size: 1.8em;
    margin-bottom: 3vh
  }
`;

export const BlackMidTitle = styled.h1`
  font-size: 2rem;
  color: #000000;
  font-weight: bold;
  text-align: left;
  margin-left: 10vw;
  margin-bottom: 5vh;

  @media (max-width: 768px) {
    font-size: 1.8em;
    margin-bottom: 3vh;
  }
`;

export const MinorMidTitle = styled.h1`
  font-size: 2rem;
  color: var(--primary-color);
  font-weight: bold;
  text-align: left;
  margin-left: 10vw;
  margin-top: 15vh;

  @media (max-width: 768px) {
    font-size: 1.7em;
    margin-top: 10vh;
  }
`;

export const BlackLittleMidTitle = styled.h1`
  font-size: 2rem;
  color: #000000;
  font-weight: bold;
  text-align: left;
  margin-left: 10vw;
  margin-top: 3vh;
  margin-bottom: 0.5vh;

  @media (max-width: 768px) {
    font-size: 1.7em;
    margin-top: 5vh;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 2vw;
  margin-bottom: 10vh;
  padding: 0 2vw;
  gap: 15px;

  @media (max-width: 768px) {
    margin-bottom: 3vh;
  }
`;

export const TransparentButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 2vw;
  margin-bottom: 5vh;
  padding: 0 2vw;
  gap: 50px;

  @media (max-width: 768px) {
    margin-bottom: 3vh;
    justify-content: flex-start;
    gap: 0px;
  }
`;


export const NonClickableBlueButton = styled.div`
  background-color: var(--primary-color);
  color: white;
  padding: 25px 25px;
  margin: 20px;
  border-radius: 50px;
  text-align: center;
  min-width: 200px;
  max-width: 250px;
  min-height: 200px;
  height: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  flex: 1 1 300px;
h4{
font-family: arial;
}
    h3{
      font-size: 2rem;
      font-weight: bold;
      color: #ffffff;
      text-align: left; 
      margin-top: 10px; 
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);

      @media (max-width: 768px) {
        font-size: 1.8em;
      }
    }

    h4{
      font-size: 0.85em;
      font-weight: normal;
      color: #ffffff;
      text-align: left; 
      margin-top: 15px; 
      padding: 0 5px; 

      @media (max-width: 768px) {
        font-size: 0.7em;
      }
    }
`;


export const TransparentButton = styled.div`
  background-color: transparent;
  color: var(--primary-color); 
  padding: 30px 30px;
  margin-right: 60px;
  border-radius: 50px;
  text-align: flex-start;
  min-width: 450px;
  max-width: 550px;
  height: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 1 300px;
`;

export const NonClickableLittleBlueButton = styled.div`
  background-color: var(--primary-color);
  color: white;
  padding: 30px 30px;
  margin: 20px;
  border-radius: 10px;
  text-align: flex-start;
  width: 6vw;
  max-height: 6vw;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events: none;
  flex: 1 1 300px;
`;

export const TransparentButtomTextBoldContainer = styled.div`
  font-size: 1.2em;
  font-weight: bold;
  color: #000000;
  text-align: left; 
  margin-top: 3vh; 
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);

  @media (max-width: 768px) {
    font-size: 0.9em;
    margin-right: 15vw;
  }
`;

export const TransparentButtomTextContainer = styled.div`
  font-size: 1em;
  font-weight: normal;
  color: #000000;
  text-align: left; 
  margin-top: 3vh; 
  padding: 0 10px; 
  font-family: arial;

  @media (max-width: 768px) {
    font-size: 0.7em;
    margin-right: 20vw;
  }
`;

export const LeftText = styled.div`
  font-size: 1em;
  font-weight: normal;
  color: #000000;
  text-align: left; 
  margin-top: 8vh;
  margin-Left: 20vh;  
  padding: 0 10px; 
 font-family: arial;

  @media (max-width: 768px) {
    font-size: 0.8em;
    margin-top: 4vh;
    margin-Left: 5vh;
    margin-right: 15vw;
  }
`;

export const RightText = styled.div`
  font-size: 1em;
  font-weight: normal;
  color: #000000;
  text-align: left; 
  margin-top: 8vh;
  margin-left: 10vh;
  margin-Right: 20vh; 
  padding: 0 10px; 
font-family: arial;

  @media (max-width: 768px) {
    font-size: 0.8em;
    margin-top: 4vh;
    margin-right: 5vh;
    text-align: right;
  }
`;

export const MaosContainer = styled.img`
  width: 100vw;
  height: auto;
  object-fit: fill;
`;

export const BottomSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
`;

export const FaixaEventosTextContainer = styled.div`
  display: flex; 
  flex-direction: row; 
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  position: relative;
  overflow: hidden;
  margin-top: 5vh;
  margin-bottom: 5vh;

  @media (max-width: 768px) {
    margin-top: 3vh;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const FaixaEventosTitle = styled.div`
  position: relative;
  font-size: 2rem;
  color: #ffffff;
  font-weight: bold;
  margin-left: 10vw;
  margin-top: 5vh;
  text-align: right;

  @media (max-width: 768px) {
    font-size: 2.3em;
    margin-left: 5vw;
    margin-top: 5vh;
  }
`;

export const FaixaEventosButton = styled.div`
  background-color: var(--primary-color);
  color: white;
  padding: 20px 10px;
  margin-left: 10vw;
  border: 2px solid #ffffff;
  border-radius: 20px;
  text-align: left;
  min-width: 350px;
  max-width: 400px;
  height: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
 h3{
font-family: arial;
}
  @media (max-width: 1000px) {
    min-width: 300px;
    max-width: 350px;
    margin-left: 5vw;
    margin-top: 5vh;
    margin-right: 20vw;
  }

  h3{
    font-size: 0.9em;
    font-weight: normal;
    color: #ffffff;
    text-align: left;  
    padding: 0 10px; 

    @media (max-width: 768px) {
      font-size: 0.7em;
    }
  }
`;

export const TrabalheConoscoContainer = styled.div`
  display: flex;
  margin-left: 10vw;
  margin-top: 5vh;
  margin-bottom: 15vh;
  flex-direction: row;  
  align-items: center;

  @media (max-width: 768px) {
    margin-top: 0vh;  
    flex-direction: column; 
  }
`;

export const TrabalheConoscoTextContainer = styled.div`
  margin-left: 5vw; 
  
  @media (max-width: 768px) {
    margin-left: 0; 
    margin-top: 2vh;
  }
`;

export const TrabalheConoscoImage = styled.img`
  width: 30vw;
  height: auto;
  object-fit: fill; 
  border-radius: 50px;

  @media (max-width: 768px) {
    width: 80vw; 
    margin-left: -10vw;
  }
`;

export const BottomTitle = styled.h1`
  font-size: 2rem;
  color: var(--primary-color);
  font-weight: bold;
  text-align: left;

  @media (max-width: 768px) {
    font-size: 2em;
    margin-top: 5vh;
    margin-bottom: 5vh
  }
`;

export const BlackBottomSubTitle = styled.h1`
  font-size: 1.0em;
  color: #000000;
  font-weight: bold;
  text-align: left;
  margin-top: 5vh;
  margin-bottom: 5vh;
font-family: arial;

  @media (max-width: 768px) {
    font-size: 1.2em;
    margin-bottom: 1vh;
  }
`;

export const BottomText = styled.div`
  font-size: 1em;
  font-weight: normal;
  color: #000000;
  text-align: left; 
  margin-top: 8vh;
  margin-right: 20vw;  
  padding: 0 10px; 
font-family: arial;

  @media (max-width: 768px) {
    font-size: 0.8em;
    margin-top: 4vh;
    margin-right: 15vw;
  }
`;

export const Button = styled.button`
  background-color: var(--primary-color);
  color: #ffffff;
  border: none;
  border-radius: 20px;
  padding: 2rem 4rem;
  font-size: 1em;
  font-weight: bold;
font-family: arial;
  margin-top: 10vh;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-sizing: border-box;


  @media (max-width: 768px) {
    font-size: 0.8em;
    padding: 1.5rem 3rem;
    margin-top: 5vh;
  }
`;