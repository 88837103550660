import styled from "styled-components";

export const HeaderComponentRetorno = styled.header`
display: flex;
position: fixed;
z-index: 1000;
font-size: 1.2rem; 
width: 100vw;
max-width:  100vw;         
font-weight: 900; 
min-height: 104px;
height: auto;         
background-color:var(--bottom-primary-collor-white);
padding-left: 10%;
padding-right: 10%;
align-items: center;

.ancoraRedesSociais{
display: flex;
align-items: center;
}
.btn{
cursor: pointer;
}
.opcoesNavegacao{
color: var( --primary-color);
}

img{
width: 135px;
height: 90px;

@media(max-width:600px){
  width: 120px;
  height: 80px;
}

}

.containerHeader{
    width: 100%;  
    max-width: 100%;         
    min-height: 100px;         
    background-color:var(--bottom-primary-collor-white); 
    display: flex;
    justify-content: space-between;
    align-items: center;
   z-index: 1000;
}
nav {
    display: flex;
    gap:15px;
    align-items: center;
    height: 50px ;
}

button{
    border-radius:5px;
    background-color:#102F51;
    height: 35px ;
    padding: 5px 15px;
    color: var(--bottom-primary-collor-white);
}

.containerIcons{
   display: flex;
   gap:7px;

img{
   width: 14px;
   height: 14px;
}
.iconeRedeSocialHeader{
 font-size: var(--fontSize);
  width: 2em;
  height: 2em;
  max-width: 4em;
  max-height: 4em;
}
}

@media (max-width: 1000px) {
padding-left: 4%;
padding-right: 4%;
.containerHeader{
flex-direction: column;
}
nav{
display: flex;
// flex-direction: column;
  flex-wrap: wrap;
}
}
@media (max-width: 600px) {
margin-top: -4vh;
font-size: 0.9rem;
min-height: 200px;
}
`;
