import { AreaCards } from "./style"

export const Cards = ({ id, icon, description }) => {
    return (
        <AreaCards key={id}>
            <div>
                <img src={icon} alt={description}/>
                <p>{description}</p>
            </div>
        </AreaCards>
    )
}