import {
    ContainerFikOn, TopSection, MidSection, BottomSection, MainDiv, LogoContainer, TextBoldContainer, TextContainer, LogoImage, Button, SideImage,
    SubTitleLine, ButtonGroup, NonClickableButton, IconButton, WriteTextBoldContainer, WriteTextContainer, Funcionario, BottomTextContent, BottomTitle, BottomText
}
    from "./style";
import { useContext } from "react";
import FikContext from "../../Contexts/FikSiteContext";

import FikOnLogo from "../../Assets/FikOn/FikOnLogo.svg";
import SideImg from '../../Assets/FikOn/BackgroundCadeado.png';
import Funcionarios from '../../Assets/FikOn/Funcionarios.png';
import IconButton1 from '../../Assets/FikOn/Icone01.svg';
import IconButton2 from '../../Assets/FikOn/Icone02.svg';
import IconButton3 from '../../Assets/FikOn/Icone03.svg';
import IconButton4 from '../../Assets/FikOn/Icone04.svg';

export const PageFikOn = ({

}) => {

    const { handleClick } = useContext(FikContext);

    return (
        <ContainerFikOn>
            <TopSection>
                <MainDiv>
                    <LogoContainer>
                        <LogoImage src={FikOnLogo} alt="Logomarca FikOn" />
                    </LogoContainer>
                    <TextBoldContainer>
                        <p>SEGURANÇA E SIMPLICIDADE<br />NA GESTÃO DE SENHAS</p>
                    </TextBoldContainer>
                    <TextContainer>
                        <p>Atua como um cofre de senhas<br />e uma ferramenta eficiente<br />de gestão de logins.</p>
                    </TextContainer>
                    <Button onClick={handleClick}>
                        FALE COM O NOSSO TIME
                    </Button>
                </MainDiv>
                <SideImage src={SideImg} alt="Foto de Cadeado" />
            </TopSection>
            <MidSection>
                <SubTitleLine>
                    PRINCIPAIS FUNCIONALIDADES:
                </SubTitleLine>
                <ButtonGroup>
                    <NonClickableButton>
                        <IconButton>
                            <img src={IconButton1} alt="Ícone de uma tela de login de um software." />
                        </IconButton>
                        <WriteTextBoldContainer>
                            GESTÃO AUTOMATIZADA DE LOGINS:
                        </WriteTextBoldContainer>
                        <WriteTextContainer>
                            O sistema gerencia todos os logins dos colaboradores,
                            prevenindo problemas de acesso inválido que podem
                            resultar no bloqueio de contas
                        </WriteTextContainer>
                    </NonClickableButton>
                    <NonClickableButton>
                        <IconButton>
                            <img src={IconButton2} alt="Ícone de um cadeado com uma caixa de diálogo de asteriscos, contornado acima por uma seta." />
                        </IconButton>
                        <WriteTextBoldContainer>
                            TROCAS DE SENHA AUTOMATIZADA:
                        </WriteTextBoldContainer>
                        <WriteTextContainer>
                            A troca de senhas é realizada automaticamente no
                            momento adequado, evitando a expiração das
                            senhas dos usuários.
                        </WriteTextContainer>
                    </NonClickableButton>
                </ButtonGroup>
                <ButtonGroup>
                    <NonClickableButton>
                        <IconButton>
                            <img src={IconButton3} alt="Ícone de um cadeado rodeado de conexões." />
                        </IconButton>
                        <WriteTextBoldContainer>
                            SEGURANÇA APRIMORADA:
                        </WriteTextBoldContainer>
                        <WriteTextContainer>
                            Todas as senhas são armazenadas em um cofre
                            seguro, acessível apenas pelo FIK ON, e são
                            criptografadas para garantir a segurança durante
                            todo o processo de autenticação, troca e
                            redefinição de senhas.
                        </WriteTextContainer>
                    </NonClickableButton>
                    <NonClickableButton>
                        <IconButton>
                            <img src={IconButton4} alt="Ícone de engrenagem com setas ao redor." />
                        </IconButton>
                        <WriteTextBoldContainer>
                            REDUÇÃO DE INTERRUPÇÕES:
                        </WriteTextBoldContainer>
                        <WriteTextContainer>
                            Com a automação do gerenciamento de acessos e
                            redefinição de senhas, os colaboradores mantêm
                            acesso contínuo aos sistemas, minimizando
                            interrupções e aumentando a produtividade.
                        </WriteTextContainer>
                    </NonClickableButton>
                </ButtonGroup>
            </MidSection>
            <BottomSection>
                <div className="container">
                    <div className="top"></div>
                    <div className="conteudo">
                        <Funcionario src={Funcionarios} alt="Funcionários" />
                        <BottomTextContent>
                            <BottomTitle>COM O <br />FIK ON</BottomTitle>
                            <BottomText>
                                A <b>gestão de acessos</b> torna-se mais segura,
                                eficiente e sem complicações, permitindo que os
                                colaboradores se concentrem em suas
                                atividades principais sem se preocupar
                                com problemas de login e senha.
                            </BottomText>
                        </BottomTextContent>
                    </div>
                    <div className="foot"></div>
                </div>

            </BottomSection>
        </ContainerFikOn>
    );
};
