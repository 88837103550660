import { ConsultoriaStyle, ConsultoriaStyle2, ConsultoriaStyle3 } from "./style"
import capa1 from "../../Assets/Consultoria/InicialConsult-01.png"
import logo from "../../Assets/Consultoria/ConsultoriaLogo.svg"
import fluxograma from "../../Assets/Consultoria/metodologia-SCRUMcorrigido.gif"
import icon from "../../Assets/Consultoria/IconesConsult-01.svg"
import icon2 from "../../Assets/Consultoria/IconesConsult-02.svg"
import icon3 from "../../Assets/Consultoria/IconesConsult-03.svg"
import icon4 from "../../Assets/Consultoria/IconesConsult-04.svg"
import { useContext } from "react"
import { FormularioComponente } from "../../Components/FormularioComponente"
import FikContext from "../../Contexts/FikSiteContext"

const allIcons = [
    { id: 1, icons: icon, description: "Organização e estruturação com melhorias de metas e resultados" },
    { id: 2, icons: icon2, description: "Soluções customizadas para diferentes tipos e tamanhos de negócios." },
    { id: 3, icons: icon3, description: "Acompanhamento de tarefas e relatórios, assim como estoques, roteirização etc" },
    { id: 4, icons: icon4, description: "Redução de custo, tempo e velocidade de implantação." }
]

export const Consultoria = () => {
    const { handleClick } = useContext(FikContext);
    return (
        <>
            <ConsultoriaStyle>
                <div className="esquerda">
                    <div>
                        <div>
                            <img src={logo} />
                        </div>
                        <p>CRIAMOS PROCESSOS COM TECNOLOGIA   <br />
                            PARA TRANSFORMAR SEU NEGÓCIO E <br />
                            DESCOBRIR NOVAS FORMAS DE TRABALHAR.</p>
                        <ul>
                            <li>Otimização de processos</li>
                            <li>Desenvolvimento e gestão</li>
                            <li>Redução dos custos operacionais</li>
                        </ul>
                        <button ariaLabel='Fale com nosso time pelo whatsapp.' onClick={handleClick}>FALE COM O NOSSO TIME</button>
                    </div>
                </div>
                <div className="direita">
                    <img src={capa1} alt="Graficos modernos" />
                </div>
            </ConsultoriaStyle>

            <ConsultoriaStyle2 >
                <div className="containerBranco">
                    <div className="top"> </div>
                    <div className="conteudo">
                        <div className="parteDeCima">

                            <div >
                                <h2>TRABALHAMOS <br />
                                    COM VOCÊ!</h2>
                                <p>Cada projeto tem o seu tempo e a sua urgência <br />
                                    específica. Para suprir todas essas <br />
                                    necessidades, nada melhor do que trabalhar <br />
                                    bem perto de quem entende do negócio.</p>

                                <p>Sempre envolvendo o cliente, cumprimos todas <br />
                                    as etapas previstas do framework para <br />
                                    manter o nosso foco na melhor entrega do <br />
                                    valor do produto. </p>
                            </div>

                            <span>Por isso, utilizamos a Metodologia Ágil</span>

                        </div>

                        <div className="meio">
                            <div className="superior">
                                <h3>METODOLOGIA ÁGIL</h3>
                            </div>
                            <div className="inferior">
                                <img src={fluxograma} />
                            </div>
                        </div>

                        <div className="parteDeBaixo">

                            <h4>3 PILARES DA NOSSA CONSULTORIA</h4>
                            <ul className="ulPilaresConsultoria">
                                <li>
                                    <span>O1</span>
                                    <p>DIAGNÓSTICO E
                                        IMERSÃO</p>
                                </li>
                                <li>
                                    <span>O2</span>
                                    <p>ANÁLISE E
                                        RECOMENDAÇÕES</p>
                                </li>
                                <li>
                                    <span>O3</span>
                                    <p>IMPLEMENTAÇÃO E
                                        ACOMPANHAMENTO</p>
                                </li>
                            </ul>
                        </div>

                        <div className="foot"></div>
                    </div>
                </div>
            </ConsultoriaStyle2>

            <ConsultoriaStyle3>
                <div className="containerAzul">
                    <div className="top"> </div>
                    <div className="conteudo">
                        <div className="superior">
                            <p><strong>Porque contratar</strong> a nossa consultoria de <br />
                                gestão de processos para a sua empresa?</p>

                        </div>

                        <ul>
                            {
                                allIcons.map(item => (
                                    <li className="card" key={item.id}>
                                        <div className="cardSuperior">
                                            <img src={item.icons} />
                                        </div>
                                        <div className="cardInferior">
                                            <p>{item.description}</p>
                                        </div>
                                    </li>
                                ))
                            }
                        </ul>

                        <div className="container">
                            <h3>Você está satisfeito com o nível de maturidade de  <br />gestão da sua empresa? <br />Converse com nossos especialistas</h3>

                            <FormularioComponente padding="76px 10%" />

                        </div>

                    </div>
                    <div className="foot"></div>
                </div>
            </ConsultoriaStyle3>


        </>
    )
}