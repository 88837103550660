
import logo from '../../Assets/LogoFik/logo.png';
import iconInstagram from '../../Assets/Icons/instagramIcon.svg';
import iconLinkedin from '../../Assets/Icons/linkedinIcon.svg';
import { useState } from "react";
import { useLocation } from 'react-router-dom';
import { FormularioComponenteRetorno } from './style';
import he from 'he'; // Importando a biblioteca he

export const FormularioComponente = ({ padding = "100px 10%" }) => {
    const [formData, setFormData] = useState({
        nome: '',
        cargo: '',
        email: ''
    });

    const location = useLocation(); // Obtendo a localização atual

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData({ ...formData, [id]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Montando o conteúdo HTML com a biblioteca he
        const htmlContent = `
            <h1>Informacoes do Formulario</h1>
            <p><strong>Nome:</strong> ${he.encode(formData.nome)}</p>
            <p><strong>Cargo:</strong> ${he.encode(formData.cargo)}</p>
            <p><strong>E-mail:</strong> ${he.encode(formData.email)}</p>
            <p><strong>Pagina:</strong> ${he.encode(location.pathname)}</p>
        `;

        const payload = {
            email: formData.email,
            subject: `Contato capturado na pagina ${location.pathname}`,
            html: htmlContent
        };

        try {
            const response = await fetch('https://sendmail.fikdigital.com.br/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                },
                body: JSON.stringify(payload),
            });

            if (!response.ok) {
                throw new Error('Erro na requisição');
            } else {
                setFormData({ nome: '', cargo: '', email: '' });
            }

            const resultado = await response.text();
            console.log('Resposta do servidor:', resultado);
        } catch (error) {
            console.error('Erro:', error);
        }
    };

    return (
        <FormularioComponenteRetorno padding={padding} onSubmit={handleSubmit}>
            <label htmlFor="nome" className="sr-only">Nome</label>
            <input id="nome" className="inputs" placeholder="Nome" value={formData.nome} onChange={handleChange} />

            <label htmlFor="cargo" className="sr-only">Cargo</label>
            <input id="cargo" className="inputs" placeholder="Cargo" value={formData.cargo} onChange={handleChange} />

            <label htmlFor="email" className="sr-only">E-mail</label>
            <input id="email" className="inputs" placeholder="E-mail" value={formData.email} onChange={handleChange} />

            <button type="submit" aria-label="Enviar seus dados para nosso time entrar em contato." className="envio">ENVIAR</button>
        </FormularioComponenteRetorno>
    );
};
