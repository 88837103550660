import styled from "styled-components";

export const FormularioComponenteRetorno = styled.form`
  display: flex;
  width: 80%;
  max-width: 100vw;
  font-family: Orbitron;
  font-weight: 900;
  background-color: transparent;

  @media (min-width: 1050px) {
    padding: ${(props) => props.padding || "100px 10%"};
    flex-direction: row;
  }

  .btn {
    cursor: pointer;
  }
  .inputs {
    background-color: transparent;
    border: 1px solid white;
    border-radius: 0px;
    padding: 10px 15px;
    font-size: 1em;
    line-height: 1em;
color: white;
  }
  .inputs::placeholder {
    color: white;
  }
  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
  .envio {
    padding: 10px 15px;
    font-size: 1em;
    background-color: white;
    color: var(--primary-color);
    font-weight: bold;
    min-width: 130px;
    z-index: 12;
    min-width: 130px;
  }
`;
