import imagemAdorno from "../../Assets/FikAnalytics/adorno.svg";
import cover2 from "../../Assets/FikAutomation/fundoBrancoFormatado1.png";
import styled from "styled-components";

export const FikAutomatonStyle = styled.main`
  width: 100vw;
  height: auto;
  margin-top: 13em;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 8em;
  font-size: var(--fontSize);

 .conteudo2FikAutomation {
color: black   !important;
font-family: arial;
}

  .left {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 4rem;
    padding: 5%;

    /* outline: 1px red solid; */
  }

  .left div {
    width: 90vw;
    justify-content: space-around;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 30px;

    //outline: 1px red solid;
  }

  .left h2 {
    color: var(--primary-color);
    font-weight: bold;
    font-size: 1.8rem;
    line-height: 1em;
    word-wrap: break-word;

    //outline: 1px red solid;
  }

  .left p {
    color: var(--menu-);
    font-size: 1em;
    line-height: 1em;
    width: 100%;
    word-wrap: break-word;

    //outline: 1px red solid;
  }

  .left button {
    background-color: var(--primary-color);
font-family: arial;
    color: white;
    border-radius: 15px;
    font-weight: bold;
    font-size: 1.2em;
    line-height: 1em;
    width: 100%;
    padding: 1em;
    //outline: 1px red solid;
  }

  .right {
    display: unset;
    height: auto;
    position: relative;

    .gradient {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      opacity: 0.7;
      background-image: linear-gradient(to top, white, transparent);
      top: 0;
      left: 0;
    }

    img {
      width: 100%;
    }
  }

  @media (min-width: 320px) {
    .esquerda > h2 {
      font-size: 2rem;
    }
  }

  @media (min-width: 768px) {
    margin-top: 10em;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    top: calc(50% - 22px);
    min-height: 60vh;

    .left {
      width: 50%;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      box-sizing: border-box;
    }

    .left div {
      align-self: flex-end;
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      gap: 2rem;
    }

    .left h2 {
      color: var(--primary-color);
      font-weight: bold;
      font-size: 1.9rem;
    }

    .left p {
      color: var(--menu-);
      height: auto;
      font-size: 0.8em;
      line-height: 1em;
      width: 100%;
      padding: 1rem auto;
    }

    .left button {
      background-color: var(--primary-color);
      color: white;
      border-radius: 15px;
      font-weight: bold;
      font-size: 0.8em;
      max-width: 400px;
      height: auto;
    }

    .right {
      width: 50%;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      .gradient {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        opacity: 0.7;
        background-image: linear-gradient(to top, white, transparent);
        top: 0;
        left: 0;
      }

      img {
        width: 70%;
        /* height:100%; */
      }
    }
  }

  @media (min-width: 1024px) {
    margin-top: 4em;
    margin-bottom: -8%;

    .left h2 {
      color: var(--primary-color);
      font-weight: bold;
      font-size: 2.6rem;
      width: auto;
    }

    .left p {
      color: var(--menu-);
      height: auto;
      font-size: 0.8em;
      line-height: 1em;
      width: 100%;
      padding: 1.1rem auto;
    }

    .left button {
      background-color: var(--primary-color);
      color: white;
      border-radius: 15px;
      font-weight: bold;
      font-size: 0.8em;
      max-width: 350px;
      height: auto;
    }
  }

  @media (min-width: 1044px) {
    .left h2 {
      color: var(--primary-color);
      font-weight: bold;
      font-size: 3.1rem;
      margin-left: 12%;
    }

    .left p {
      color: var(--menu-);
      height: auto;
      font-size: 1.1em;
      line-height: 1em;
      width: 80%;
      margin-left: 12%;
      padding: 1.1rem auto;
    }

    .left button {
      background-color: var(--primary-color);
      color: white;
      border-radius: 15px;
      font-weight: bold;
      font-size: 1.3em;
      max-width: 350px;
      height: auto;
      margin-left: 12%;
    }
  }
`;

export const FikAutomatonStyle2 = styled.main`
  font-size: var(--fontSize);
.conteudo2FikAutomation {
color: black   !important;
font-family: arial;
}
  section {
    display: flex;
    flex-direction: column;
  }

  .containerBranco {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 200px auto 200px;

    width: 100vw;
    position: relative;
    margin-top: -25px;
  }

  .top {
    width: calc(100% + 8px);
    height: 100px;
    background-image: url(${cover2});
    background-position: right top;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 2;
    margin-top: -15px;
    margin-left: -4px;
    transform: scaleX(-1);
  }

  .conteudo2 {
    display: flex;
    flex-direction: column;
    position: relative;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    color: #fff;
    box-sizing: border-box;
    gap: 25px;

    .esquerda {
      width: 100vw;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 30px;

      > h2 {
        width: 90vw;
        font-size: 1.5rem;
        font-weight: 700;
        color: var(--primary-color);
        word-wrap: break-word;
        > strong {
          font-size: 1.5em;
        }
      }

      > P {
        width: 90%;
        font-size: 0.8em;
        line-height: 1em;
        color: var(--primary-color);

        br {
          display: none;
        }
      }
    }

    .direita {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 98vw;
      margin: 25px auto;
      outline: 1px solid;
    }
    .direita > img {
      width: 100%;
      align-self: center;
    }
  }

  .foot {
    display: none;
    /* width: calc(100% + 8px);
    height: 200px;
    background-image: url(${cover2});
    background-position: left bottom;
    background-repeat: no-repeat;
    background-size: unset;

    margin-top: 30px;
    margin-left: -4px;
    transform: scaleX(-1); */
  }

  @media (min-width: 375px) {
    .counteudo2 > .esquerda > h2 {
      font-size: 2rem;
    }
  }

  @media (min-width: 768px) {
    font-size: var(--fontSize);

    section {
      display: flex;
      flex-direction: row;
      min-height: 60vh;
    }

    .containerBranco {
      position: relative;
      margin-top: -2px;
    }

    .top {
      width: calc(100% + 8px);
      height: 200px;
      background-image: url(${cover2});
      background-position: right top;
      background-repeat: no-repeat;
      background-size: cover;
      z-index: 2;
      margin-top: -15px;
      margin-left: -4px;
      transform: scaleX(-1);
    }

    .conteudo2 {
      display: flex;
      position: relative;

      display: flex;
      align-items: center;
      justify-content: center;
      background-color: white;
      color: #fff;
      box-sizing: border-box;
      padding: 20px 20px;
      max-width: 100vw;

      .esquerda {
        width: 50vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 30px;

        > h2 {
          width: 80%;
          font-size: 1.6rem;
          font-weight: 700;
          color: var(--primary-color);

          > strong {
            font-size: 1.5em;
          }
        }

        > P {
          width: 80%;
          font-size: 1em;
          line-height: 1em;
          color: var(--primary-color);

          br {
            display: none;
          }
        }
      }

      .direita {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 50vw;
      }
    }
  }

  @media (min-width: 1024px) {
    font-size: var(--fontSize);
    section {
      display: flex;
      flex-direction: row;
    }
    .containerBranco {
      position: relative;
      margin-top: -25px;
    }

    .top {
      width: calc(100% + 8px);
      height: 200px;
      background-image: url(${cover2});
      background-position: right top;
      background-repeat: no-repeat;
      background-size: cover;
      z-index: 2;
      margin-top: -15px;
      margin-left: -4px;
      transform: scaleX(-1);
    }

    .conteudo2 {
      display: flex;
      position: relative;
      min-height: 300px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: white;
      color: #fff;
      box-sizing: border-box;
      padding: 20px 20px;
      max-width: 100vw;

      .esquerda {
        width: 50vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 30px;

        > h2 {
          line-height: 1.2em;
          font-size: 2rem;
          font-weight: 500;
          color: var(--primary-color);

          > strong {
            font-size: 1.5em;
            font-weight: bold;
          }
        }

        > P {
          font-size: 1em;
          line-height: 1em;
          color: var(--primary-color);
        }
      }
    }

    /* .foot {
      width: calc(100% + 8px);
      height: 200px;
      background-image: url(${cover2});
      background-position: left bottom;
      background-repeat: no-repeat;
      background-size: unset;
      margin-top: 30px;
      margin-left: -4px;
      transform: scaleX(-1);
    } */
  }

  @media (min-width: 1044px) {
    .conteudo2 {
      display: flex;
      position: relative;
      min-height: 300px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: white;
      color: #fff;
      box-sizing: border-box;
      padding: 20px 20px;
      max-width: 100vw;

      .esquerda {
        > h2 {
          line-height: 1.2em;
          font-size: 1.9rem;
          font-weight: 500;
          color: var(--primary-color);

          > strong {
            font-size: 2.9rem;
            font-weight: bold;
          }
        }

        > P {
          /* width: 50%; */
          font-size: 1em;
          line-height: 1em;
          color: var(--primary-color);
        
          br {
            display: flex;
          }
        }
      }

      .direita {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 50vw;
        margin: 25px auto;
        outline: 1px solid;
      }
      .direita > img {
        width: 60%;
        align-self: center;
      }
    }

    .foot {
      display: none;
      /* width: calc(100% + 8px);
      height: 200px;
      background-image: url(${cover2});
      background-position: left bottom;
      background-repeat: no-repeat;
      background-size: unset;
      margin-top: 30px;
      margin-left: -4px;
      transform: scaleX(-1);
    } */
    }
  }

  @media (min-width: 1440px) {
    .conteudo2 {
      display: flex;
      position: relative;
      min-height: 300px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: white;
      color: #fff;
      box-sizing: border-box;
      padding: 20px 20px;
      max-width: 100vw;

      .esquerda {

        > h2 {
          line-height: 1.2em;
          font-weight: 500;
          color: var(--primary-color);
          font-size: 2rem;
          > strong {
            font-size: 1.5em;
            font-weight: bold;
          }
        }

        > P {
          font-size: 1.2em;
          line-height: 1em;
          color: var(--primary-color);
        
          br {
            display: flex;
          }
        }

      }
 .conteudo2FikAutomation {
color: black   !important;
font-family: arial;
}

      .direita {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 50vw;
        margin: 25px auto;
        outline: 1px solid;
      }
      .direita > img {
        width: 60%;
        align-self: center;
      }
    }

    .foot {
      display: none;
      /* width: calc(100% + 8px);
      height: 200px;
      background-image: url(${cover2});
      background-position: left bottom;
      background-repeat: no-repeat;
      background-size: unset;
      margin-top: 30px;
      margin-left: -4px;
      transform: scaleX(-1); */
    }
  }
`;

export const FikAutomatonStyle3 = styled.main`
  font-size: var(--fontSize);
.conteudo2FikAutomation {
color: black   !important;
font-family: arial;
}
  .containerAzul {
    width: 100vw;

    position: relative;

    margin-top: -22%;

    @media (max-height: 600px) {
      margin-top: -20px;
    }

    @media (min-width: 1050px) {
      margin-top: -80px;
    }

    @media (min-width: 850px) {
      margin-top: -218px;
    }
  }

  .top {
    width: calc(100% + 5px);

    height: 200px;

    background-image: url(${imagemAdorno});

    background-position: left top;

    background-repeat: no-repeat;

    background-size: cover;

    z-index: 900;

    margin-top: 20px;

    margin-left: -4px;
  }

  .conteudo {
    position: relative;

    min-height: 300px;

    display: flex;

    align-items: center;

    justify-content: center;

    background-color: #2b9bc6;

    color: #fff;

    box-sizing: border-box;

    padding: 20px 20px;

    max-width: 100vw;

    margin-top: -5em;
  }

  .foot {
    width: calc(100% + 8px);

    height: 120px;

    background-image: url(${imagemAdorno});

    background-position: left bottom;

    background-repeat: no-repeat;

    background-size: cover;

    margin-top: -4px;

    margin-left: -4px;
  }

  .faixasContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 26px;
  }
  .title {
    display: flex;
    height: auto;
    max-width: 90vw;
    width: 100%;

    > h2 {
      color: var(--bottom-primary-collor-white);
      font-size: 1.2em;
      line-height: 1em;
      font-weight: 700;
      align-self: flex-start;
      margin-top: 1rem;
      word-wrap: break-word;
    }
  }

  @media (min-width: 768px) {
    .top {
      margin-top: 5px;
    }

    .conteudo {
      margin-top: -3em;
    }

    .faixasContainer {
      gap: 0;
    }

    .title {
      width: 34em;
      height: auto;
    }

    .title h2 {
      color: white;
      font-size: 1.5em;
      font-weight: 700;
      margin-bottom: 30px;
    }
  }

  @media (min-width: 1024px) {
    .top {
      margin-top: 5px;
    }

    .conteudo {
      margin-top: -3em;
    }

    .faixasContainer {
    }

    .title {
      width: 59em;
      height: auto;
    }

    .title h2 {
      color: white;
      font-size: 2 3.5em;
      font-weight: 700;
      margin-bottom: 30px;
    }
  }

  @media (min-width: 1044px) {
    .foot {
      width: calc(100% + 8px);

      height: 200px;

      background-image: url(${imagemAdorno});

      background-position: left bottom;

      background-repeat: no-repeat;

      background-size: cover;

      margin-top: -4px;

      margin-left: -4px;
    }
  }
`;

export const FikAutomatonStyle4 = styled.main`
  width: 100vw;
.conteudo2FikAutomation {
color: black   !important;
font-family: arial;
}
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: var(--fontSize);
  position: relative;
  margin-bottom: 40px;

  .left {
    display: block;
    width: 100vw;
    height: 80vh;
    background-image: url(${(props) => props.image});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    display: unset;
  }

  .right {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 30px;
    padding: 5%;
    box-sizing: border-box;
    word-wrap: break-word;

    h2 {
      width: 100%;
      color: var(--primary-color);
      text-align: left;
      font-size: 1.7rem;
      font-weight: bold;

      strong {
        font-size: 1em;
      }
    }

    p {
      width: 100%;
      color: var(--menu-blue-color);
      text-align: center;
      font-size: 1em;
      font-weight: 500;
      text-align: left;
      line-height: 1em;
      letter-spacing: 1.3px;
    }
  }

  @media (min-width: 768px) {
    width: 100vw;
    min-height: 60vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: var(--fontSize);
    position: relative;
    margin-top: -6%;

    .left {
      width: 50vw;
      background-image: url(${(props) => props.image});
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      display: unset;
    }

    .right {
      width: 50vw;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 30px;
      padding: 2%;

      h2 {
        width: 100%;
        color: var(--primary-color);
        text-align: left;
        font-size: 2.1rem;
        font-weight: bold;

        strong {
          font-size: 1em;
        }
      }

      p {
        width: 100%;
        color: var(--menu-blue-color);
        text-align: center;
        font-size: 1em;
        font-weight: 500;
        text-align: left;
        line-height: 1em;
        letter-spacing: 1.3px;
      }
    }
  }

  @media (min-width: 1044px) {
    width: 100vw;
    min-height: 70vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: var(--fontSize);
    position: relative;
    margin-top: -6%;

    .left {
      width: 50vw;
      background-image: url(${(props) => props.image});
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      display: unset;
    }

    .right {
      width: 50vw;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 30px;
      padding: 2%;

      h2 {
        width: 100%;
        color: var(--primary-color);
        text-align: left;
        font-size: 2rem;
        font-weight: bold;

        strong {
          font-size: 1em;
        }
      }

      p {
        width: 100%;
        color: var(--menu-blue-color);
        text-align: center;
        font-size: 1.2em;
        font-weight: 500;
        text-align: left;
        line-height: 1em;
        letter-spacing: 1.3px;
      }
    }
  }

`;
