import styled from "styled-components";

export const MenuDeAcessibilidadeRetorno = styled.section`
position: fixed;
top:0;
left:0;
z-index: 1001;
font-size:14px;
width: 100vw;
max-width: 100%;           
font-family: arial;
font-weight: 500; 
min-height: 50px;         
background-color: #102F51;
display: flex;
padding-left: 10%;
padding-right: 10%;
justify-content: flex-end;
align-items: center;
gap: 10px;
span{
font-size: 16px;
}
.containerControleLetras{
  display: flex;
  align-items: center;
  color: var(--bottom-primary-collor-white);
  gap: 14px;
}
.containerControleLetrasCont{
  /* margin-right:35px; */

}
div{
display: flex;
}

.containerControleLetrasbtns{
display: flex;
max-width: 150spx; 
gap:5px;
}

.buttonIconsBarraAcessibilidade{
  display:flex;
  color: var(--bottom-primary-collor-white);
  border: none; 
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bottom-primary-collor-white);
  height: 32px; 
  width: 36px;
  border-radius:5px;

figure{
  display:flex;
  align-items: center;
 color:var( --primary-color);
 font-size: 22px;
 font-weight: 1300; 
}
.btnsAcessibilidade{
display: flex;
justify-content: center;
font-size: 20px;
font-weight: 900;
height: 36px;
    width: 36px;
}
}

@media (max-width: 1000px) {
display:flex;
flex-wrap: wrap;
}

`;
